import React, { useState } from 'react';
import { Copy } from 'lucide-react';
import './RunDetailsSharingComponent.css';

// Icon to emoji/text mappings
const iconTextMapping = {
  day: '🗓️',           // Calendar emoji for "day"
  time: '⏰',          // Clock emoji for "times"
  distance: '🏃‍♂️',   // Runner emoji for "distance"
  location: '📍',      // Pin emoji for "location"
  info: 'ℹ️',         // Info emoji for "info"
};

const RunDetailsSharingComponent = ({ clubTitle, runDays, clubId }) => {
  const [isCopied, setIsCopied] = useState(false);

  // Ensure clubId is valid, otherwise show a placeholder link
  const clubstaProfileLink = clubId 
    ? `https://www.clubsta.co/clubs/sanfrancisco/${clubId}` 
    : 'https://www.clubsta.co';  // Fallback in case clubId is missing

  // Format the run details to include structured day, times, distance, location, and info
  const formattedRunDays = runDays.map(runDay => {
    const times = Array.isArray(runDay.times) ? runDay.times.join(', ') : runDay.times;
    
    let runDetails = `
${iconTextMapping.day} Day: ${runDay.day}
${iconTextMapping.time} Time: ${times}
${iconTextMapping.distance} Distance: ${runDay.distance}
${iconTextMapping.location} Location: ${runDay.location}`;

    // Only add the info field if it exists and is not empty
    if (runDay.info && runDay.info.trim() !== '') {
      runDetails += `\n${iconTextMapping.info} Other Info: ${runDay.info}`;
    }

    return runDetails.trim();
  }).join('\n\n');

  const shareText = `Check out the run details for ${clubTitle} on Clubsta!

Run Details:
${formattedRunDays}

View ${clubTitle}'s Profile: ${clubstaProfileLink}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareText).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="run-details-sharing">
      <button onClick={copyToClipboard} className="copy-button">
        <Copy size={16} />
        {isCopied ? 'Details copied!' : 'Copy Run Details'}
      </button>
    </div>
  );
};

export default RunDetailsSharingComponent;