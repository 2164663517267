import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CLUBDATA } from '../assets/runClubDataIndex';
import MediaCard from './CardComponent';
import BackButton from './backbutton/BackButton';
import './CategoryDetail.css';

const CategoryDetail = ({ selectedCity }) => {
  const { categoryName } = useParams();

  // Decode the category name from the URL
  const decodedCategoryName = decodeURIComponent(categoryName);

  useEffect(() => {
    // Set the scroll position to top immediately when the component mounts
    window.scrollTo(0, 0);
  }, []);

  // Filter clubs based on both category and city
  const filteredClubs = CLUBDATA.filter(club => 
    club.city === selectedCity && 
    club.categories && 
    club.categories.some(cat => cat.toLowerCase() === decodedCategoryName.toLowerCase())
  );

  console.log("Selected City:", selectedCity);
  console.log("Category Name:", decodedCategoryName);
  console.log("Filtered Clubs:", filteredClubs);

  return (
    <div className="category-detail">
      <BackButton/>
      <h2>{decodedCategoryName} Running Clubs in {selectedCity}</h2>
      <div className="card-section">
        {filteredClubs.length > 0 ? (
          filteredClubs.map((club) => (
            <MediaCard key={club.id} data={club} />
          ))
        ) : (
          <p>No clubs found for {decodedCategoryName} in {selectedCity}.</p>
        )}
      </div>
    </div>
  );
};

export default CategoryDetail;