import React, { useState } from 'react';
import { Copy } from 'lucide-react';
import './ShareClub.css';

const ShareClub = ({ clubUrl, clubTitle }) => {
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(clubUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <button className="share-club-button" onClick={handleShare}>
      <Copy size={18} />
      <span>{copied ? 'Link copied' : 'Copy Club Link'}</span>
    </button>
  );
};

export default ShareClub;
