import React from 'react';
import './dayChip.css';

const DayOfWeekChip = ({ days }) => {
  // Ensure days is an array and filter out any undefined or null values
  const daysArray = (Array.isArray(days) ? days : [days]).filter(day => day != null);

  return (
    <div className="day-chip-container">
      {daysArray.map((day, index) => (
        <span
          key={index}
          className={`day-chip day-${(day || '').toLowerCase()}`}
        >
          {day}
        </span>
      ))}
    </div>
  );
};

export default DayOfWeekChip;