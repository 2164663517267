// File: assets/runClubDataIndex.js

import { SF_RUN_CLUB_DATA } from './sfrunClubData.js';
import { EAST_BAY_RUN_CLUB_DATA } from './eastBayRunClubData.js';

// Combine all run club data into a single array
export const ALL_RUN_CLUB_DATA = [
  ...SF_RUN_CLUB_DATA,
  ...EAST_BAY_RUN_CLUB_DATA
];

// If you want to keep the original CLUBDATA name for backwards compatibility
export const CLUBDATA = ALL_RUN_CLUB_DATA;

// Generate a list of unique cities
export const CITIES = [...new Set(ALL_RUN_CLUB_DATA.map(club => club.city))];

