import React from 'react';
import { ALL_CLUB_PROFILES } from '../assets/clubProfilesIndex'; // Adjust the path as needed
import './Host.css';

const Host = ({ clubId }) => {
  const club = ALL_CLUB_PROFILES[clubId];
  
  if (!club || !club.host) {
    return null; // Don't render anything if there's no host information
  }

  return (
    <div className="host-container">
      <img src={club.host.image} alt={`${club.host.name}`} className="host-image" />
      <span className="host-title">Hosted by {club.host.name}</span>
    </div>
  );
};

export default Host;