import React from "react";
import { useParams } from "react-router-dom";
import { BLOGDATA } from "../assets/blogData";
import ReactMarkdown from "react-markdown";
import "./BlogPage.css"; // Ensure you have a CSS file for styling
import BackButton from "./backbutton/BackButton";

const BlogPage = () => {
  const { id } = useParams();
  const blog = BLOGDATA.find((blog) => blog.id === id);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="blog-page-container">
      <BackButton/>
      <h1>{blog.title}</h1>
      <p>{blog.date}</p>
      <ReactMarkdown>{blog.content}</ReactMarkdown>
    </div>
  );
};

export default BlogPage;
