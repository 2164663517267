import React from 'react';
import './ClubMapRun.css';

const ClubMapRun = ({ googleMapLink }) => {
  return (
    <div className="club-map-run-container">
      <iframe
        title="Club Location Map"
        src={googleMapLink}
        allowFullScreen
      />
    </div>
  );
};

export default ClubMapRun;
