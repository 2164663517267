import React, { useEffect } from "react";
import { BLOGDATA } from "../assets/blogData";
import { Link } from "react-router-dom";
import "./blogs.css";
import BackButton from "./backbutton/BackButton";

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="blogs-container">
      <BackButton />
      <header className="blogs-header">
        <h1 className="blogs-title">Blog</h1>
      </header>
      <div className="blogs-content">
        <div className="featured-blog">
          <Link to={`/blogs/${BLOGDATA[0].id}`} className="blog-link">
            <div className="featured-content">
              <h2 className="blog-title">{BLOGDATA[0].title}</h2>
              <p className="blog-description">{BLOGDATA[0].description}</p>
              <p className="blog-date">{BLOGDATA[0].date}</p>
            </div>
            {BLOGDATA[0].image && (
              <div className="blog-image-container">
                <img src={BLOGDATA[0].image} alt={BLOGDATA[0].title} className="blog-image featured-image" />
              </div>
            )}
          </Link>
        </div>
        <div className="other-blogs">
          {BLOGDATA.slice(1).map((post, index) => (
            <Link key={index} to={`/blogs/${post.id}`} className="blog-link">
              <div className="blog-card">
                <div className="blog-content">
                  <h3 className="blog-title">{post.title}</h3>
                  <p className="blog-description">{post.description}</p>
                  <p className="blog-date">{post.date}</p>
                </div>
                {post.image && (
                  <div className="blog-image-container">
                    <img src={post.image} alt={post.title} className="blog-image" />
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blogs;