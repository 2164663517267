import React from "react";
import "./navbar.css";
import logoSvg from "../../assets/clubsta1.svg";
import { Link } from "react-router-dom";
import ClaimButton from "../ClaimButton";
import CitySelector from "../CitySelector/CitySelector";

export default function NavBar({ selectedCity, setSelectedCity }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar-wrapper">
      <div className="navbar-container">
        <Link to="/">
          <img src={logoSvg} alt="Logo" className="navbar-logo" />
        </Link>
        <CitySelector selectedCity={selectedCity} setSelectedCity={setSelectedCity} />
        
        <div className="navbar-hamburger" onClick={toggleMenu}>
          <div className="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`navbar-actions ${isMenuOpen ? "open" : ""}`}>
          <Link to="/" className="navbar-link">Home</Link> {/* Home link added */}
          <Link to="/aboutus" className="navbar-link">About Us</Link>
          <ClaimButton text="Claim Your Club" to="/claimclub" />
        </div>
      </div>
    </div>
  );
}
