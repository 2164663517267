import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ReactGA from "react-ga4";  // Use react-ga4 for Google Analytics 4
import './NavyPanel.css';

function NavyPanel({ searchTerm, setSearchTerm }) {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const [typingTimeout, setTypingTimeout] = useState(null);  // Timeout ID for debouncing

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);  // Update the search term state in parent component

    // Clear the previous timeout when the user continues typing
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to capture when the user stops typing (500ms delay)
    const timeoutId = setTimeout(() => {
      setDebouncedTerm(term);  // Debounced search term is set after the delay
    }, 500);  // 500ms debounce time

    // Store the timeout ID so it can be cleared later if necessary
    setTypingTimeout(timeoutId);
  };

  // This effect runs when the debounced term changes (i.e., user stops typing)
  useEffect(() => {
    if (debouncedTerm.trim()) {
      // Send the debounced search term to Google Analytics
      ReactGA.event({
        category: 'Search',
        action: 'Search Term Entered',
        label: debouncedTerm,
      });
      console.log("Sending search term to GA:", debouncedTerm);
    }
  }, [debouncedTerm]);



  return (
    <div className="navy-panel">
      <h1>Find the Best Running Clubs in the Bay Area</h1>
      <p>Join a Running Group Near You</p>
      <div className="search-bar-container">
        <div className="search-bar-wrapper">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            className="search-bar"
            placeholder="Search by day or club"
            value={searchTerm}
            onChange={handleInputChange}  // Capture the search term and debounce the action
          />
          <button className="search-button">
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default NavyPanel;
