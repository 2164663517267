import React from 'react';
import './ClubMap.css';

const ClubMap = ({ googleMapLink }) => {
  return (
    <div className="map-container">
      <iframe
        title="Club Location Map"
        src={googleMapLink}
        allowFullScreen
      />
    </div>
  );
};

export default ClubMap;
