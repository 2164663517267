import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faRunning, faUsers, faDollarSign, faFemale, faSun, faMoon, faStar, faNetworkWired, faBeer, faClock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './Categories.css';

const allCategories = [
  { name: 'Social', icon: faHeart },
  { name: 'Competitive', icon: faRunning },
  { name: 'Free', icon: faDollarSign },
  { name: 'Female-only', icon: faFemale },
  { name: 'Morning', icon: faSun },
  { name: 'Evening', icon: faMoon },
];

const Categories = ({ selectedCity }) => {
  const navigate = useNavigate();

  const handleCategoryClick = (categoryName) => {
    console.log("Clicked category:", categoryName);
    console.log("Selected city:", selectedCity);
    navigate(`/club/${encodeURIComponent(categoryName)}`);
  };

  return (
    <div className="categories-container">
      <div className="categories">
        {allCategories.map((category, index) => (
          <div key={index} className="category" onClick={() => handleCategoryClick(category.name)}>
            <FontAwesomeIcon icon={category.icon} className="icon" />
            <p>{category.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;