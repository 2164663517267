import React from 'react';
import './ClaimButton.css'; // We'll create this CSS file next
import { Link } from 'react-router-dom';

export default function ClaimButton({ text, to }) {
  return (
    <Link to={to} className="claim-button">
      {text}
    </Link>
  );
}
