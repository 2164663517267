import React from "react";
import { Grid, Typography, Container, Box, Link as MuiLink, useMediaQuery, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: "none",
  fontSize: '14px',
  marginBottom: '8px',
  transition: 'color 0.3s',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const FooterSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(3),
}));

const FooterTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  fontSize: '16px',
}));

export default function Footer() {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Box component="footer" sx={{ backgroundColor: "#f8f9fa", py: 6, fontFamily: 'Arial, sans-serif' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <FooterSection>
              <FooterTitle variant="h6">Info</FooterTitle>
              <StyledLink to="/aboutus">About Clubsta</StyledLink>
              <StyledLink to="/blogs">Blog</StyledLink>
              <StyledLink to="/releases">Releases</StyledLink>
              <StyledLink to="/help">Help</StyledLink>
            </FooterSection>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FooterSection>
              <FooterTitle variant="h6">Follow us</FooterTitle>
              <MuiLink href="https://www.instagram.com/clubstasf/" target="_blank" rel="noopener noreferrer" sx={{ color: 'text.secondary', '&:hover': { color: 'primary.main' } }}>
                <FontAwesomeIcon icon={faInstagram} size="lg" />
                <Typography variant="body2" sx={{ display: 'inline-block', ml: 1 }}>
                  Instagram
                </Typography>
              </MuiLink>
            </FooterSection>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FooterSection>
              <FooterTitle variant="h6">About</FooterTitle>
              <Typography variant="body2" color="text.secondary">
                Made with ❤️ in San Francisco, CA 🇺🇸
              </Typography>
            </FooterSection>
          </Grid>
        </Grid>
        <Box sx={{ mt: 4, pt: 2, borderTop: '1px solid', borderColor: 'divider' }}>
          <Typography variant="body2" color="text.secondary" align={isSmallScreen ? 'center' : 'left'}>
            © {new Date().getFullYear()} clubsta.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}