import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import "./card.css";
import "./VerifiedBadge.css";
import DayOfWeekChip from './dayChip';

const globalMuteState = {
  isMuted: false,
  listeners: new Set(),
  toggle() {
    this.isMuted = !this.isMuted;
    this.notifyListeners();
  },
  setMuted(muted) {
    this.isMuted = muted;
    this.notifyListeners();
  },
  addListener(listener) {
    this.listeners.add(listener);
  },
  removeListener(listener) {
    this.listeners.delete(listener);
  },
  notifyListeners() {
    this.listeners.forEach(listener => listener(this.isMuted));
  }
};

export default function CardComponent(props) {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(globalMuteState.isMuted);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();
  const observerRef = useRef(null);

  useEffect(() => {
    setIsTouchDevice(
      "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );

    const handleMuteChange = (muted) => {
      setIsMuted(muted);
      if (videoRef.current) {
        videoRef.current.muted = muted;
      }
    };

    globalMuteState.addListener(handleMuteChange);

    return () => {
      globalMuteState.removeListener(handleMuteChange);
    };
  }, []);

  const playVideo = useCallback(() => {
    const video = videoRef.current;
    if (!video || !isVideoLoaded) return;

    video.muted = globalMuteState.isMuted;
    const playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Error playing video:", error);
          if (!video.muted) {
            video.muted = true;
            globalMuteState.setMuted(true);
            video.play().catch(e => console.error("Muted autoplay also failed:", e));
          }
        });
    }
  }, [isVideoLoaded]);

  const pauseVideo = useCallback(() => {
    const video = videoRef.current;
    if (video && !video.paused) {
      video.pause();
      setIsPlaying(false);
    }
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (video.currentTime >= 30) {
        video.currentTime = 0;
      }
    };

    const handleLoadedData = () => {
      setIsVideoLoaded(true);
    };

    video.addEventListener("timeupdate", handleTimeUpdate);
    video.addEventListener("loadeddata", handleLoadedData);

    return () => {
      video.removeEventListener("timeupdate", handleTimeUpdate);
      video.removeEventListener("loadeddata", handleLoadedData);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: isTouchDevice ? 0.9 : 0.5, // 90% visibility for mobile, 50% for desktop
    };

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const isNowInView = entry.isIntersecting;
        setIsInView(isNowInView);
        
        if (isNowInView) {
          if (!isVideoLoaded) {
            video.load();
          } else if (isTouchDevice) {
            playVideo();
          }
        } else {
          pauseVideo();
        }
      });
    }, options);

    observerRef.current.observe(video);

    return () => {
      if (observerRef.current && video) {
        observerRef.current.unobserve(video);
      }
    };
  }, [isVideoLoaded, isTouchDevice, playVideo, pauseVideo]);

  const handleMouseEnter = () => {
    setIsHovering(true);
    if (!isTouchDevice && isInView && isVideoLoaded) {
      playVideo();
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    if (!isTouchDevice) {
      pauseVideo();
    }
  };

  const handleVideoClick = (e) => {
    if (isTouchDevice) {
      e.preventDefault();
      e.stopPropagation();
      if (isPlaying) {
        pauseVideo();
      } else {
        playVideo();
      }
    }
    // For desktop, do nothing here to allow the Link component to handle navigation
  };

  const toggleMute = (e) => {
    e.stopPropagation();
    e.preventDefault();
    globalMuteState.toggle();
  };

  const renderMedia = () => {
    if (props.data.videoSrc) {
      return (
        <div
          className="video-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleVideoClick}
        >
          <video
            ref={videoRef}
            className="media-video"
            playsInline
            preload="metadata"
            poster={props.data.img}
            muted={isMuted}
            loop
          >
            <source src={props.data.videoSrc} type="video/mp4" />
          </video>
          {isTouchDevice && (
            <div className="video-overlay" onClick={handleVideoClick}></div>
          )}
          <button
            className="mute-button"
            onClick={toggleMute}
            aria-label={isMuted ? "Unmute video" : "Mute video"}
          >
            {isMuted ? (
              <FaVolumeMute size={18} color="white" />
            ) : (
              <FaVolumeUp size={18} color="white" />
            )}
          </button>
        </div>
      );
    } else if (props.data.img) {
      return (
        <img
          src={props.data.img}
          className="img"
          alt={props.data.title}
          loading="lazy"
        />
      );
    } else {
      return <div className="placeholder-image">No image available</div>;
    }
  };

  return (
    <div className="card-wrapper">
      <Link to={`/club/${props.data.city.toLowerCase().replace(/\s+/g, '')}/${props.data.id}`} className="card-link">
        <div className="img-holder">{renderMedia()}</div>
        <div className="card-content">
          <div className="card-title-wrapper">
            <div className="card-title">{props.data.title}</div>
          </div>
          <div className="card-subtitle">{props.data.subtitle}</div>
          <div className="card-description">{props.data.description}</div>
          <DayOfWeekChip days={props.data.daysOfWeek} />
        </div>
      </Link>
    </div>
  );
}