// src/Components/BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BackButton.css'; // Import the CSS file for styling

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    // Check if there's a previous entry in the history
    if (window.history.length > 1) {
      navigate(-1); // This will take the user to the previous page in their history
    } else {
      // If there's no previous history, navigate to the Clubsta home page
      window.location.href = 'https://www.clubsta.co';
    }
  };

  return (
    <div className="back-button-container">
      <button onClick={handleBackClick} className="back-button">
        ← Back
      </button>
    </div>
  );
};

export default BackButton;