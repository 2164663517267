import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Releases.css';
import BackButton from './backbutton/BackButton';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const updates = [
  {
    date: "What's New: 25th September, 2024",
    changes: [
      "Video-based profiles to make club content more engaging and improve selection decisions."
    ]
  },
  {
    date: "What's New: 20th September, 2024",
    changes: [
      "Ability to copy the club link and share with friends.",
      "New types of badges to know club cadence and if it is free.",
      "New icon for run details to express additional information.",
    ]
  },
  {
    date: "What's New: 8th September, 2024",
    changes: [
      "New badges including verification and activity type badges to help with selection decisions.",
      "Google maps embeds are being used to help users discover meet points and proximity to their nearest run club.",
      "Max images per club profile has been raised from one to three to give a better sense of club culture and general vibes.",
      "Redesigned run details to account for the fact that many clubs have multiple runs per week and meet in different locations and have different workout types."
    ]
  },
  {
    date: "What's New: 2nd September, 2024",
    changes: [
      "Filter running clubs by city. This will allow us to onboard new regions.",
      "New 'feeds' section to embed run club social media feeds.",
      "Image support added for blogs",
      "Copy run details functionality so that clubs can easily share weekly run details."
    ]
  },
  {
    date: "What's New: August 31, 2024",
    changes: [
      "Users can now see a club's Instagram feed attached to the club profile.",
      "Club profiles have been slightly rewritten to make discovery of information easier",
      "Footer is redesigned for a cleaner and more visually appealing look.",
    ]
  },
  {
    date: "What's New: August 27, 2024",
    changes: [
      "Navigation improvements, including supporting back buttons.",
      "Help section added to footer.",
    ]
  },
  {
    date: "What's New: August 26, 2024",
    changes: [
      "Additional running club selection for San Francisco.",
      "'Claim Your Club' button, offering running clubs the ability to claim and manage their clubs.",
      "Releases page for users to see what's new or what's changed on the site.",
    ]
  }
];

export default function Releases() {
  return (
    <>
      <ScrollToTop />
      <div className="releases-container">
        <BackButton/>
        <header className="releases-header">
          <h1 className="releases-title">Releases</h1>
          <p className="releases-subtitle">Here you can find weekly updates on what's changed or improved on clubsta.</p>
        </header>
        <main className="releases-content">
          {updates.map((update, index) => (
            <section key={index} className="releases-section">
              <h2 className="section-title">{update.date}</h2>
              <div className="section-content">
                <ul className="releases-list">
                  {update.changes.map((change, i) => (
                    <li key={i}>{change}</li>
                  ))}
                </ul>
              </div>
            </section>
          ))}
        </main>
      </div>
    </>
  );
}