import React from 'react';
import { CalendarDays, DollarSign, Gift, Users, Trophy, Mountain, Timer, Footprints, Dumbbell } from 'lucide-react';
import './badge.css';

const Badge = ({ type }) => {
  const getBadgeDetails = (type) => {
    switch (type.toLowerCase()) {
      case 'weekly':
        return { icon: <CalendarDays size={16} />, text: 'Weekly' };
      case 'paid':
        return { icon: <DollarSign size={16} />, text: 'Paid' };
      case 'free':
        return { icon: <Gift size={16} />, text: 'Free' };
      case 'social':
        return { icon: <Users size={16} />, text: 'Social' };
      case 'competitive':
        return { icon: <Trophy size={16} />, text: 'Competitive' };
      case 'trails':
        return { icon: <Mountain size={16} />, text: 'Trails' };
      case 'track':
        return { icon: <Timer size={16} />, text: 'Track' };
      case 'runs':
        return { icon: <Footprints size={16} />, text: 'Runs' };
      case 'group-workout':
        return { icon: <Dumbbell size={16} />, text: 'Group Workout' };
      default:
        return null;
    }
  };

  const badgeDetails = getBadgeDetails(type);

  if (!badgeDetails) return null;

  return (
    <span className={`badge badge-${type.toLowerCase()}`}>
      <span className="badge-icon">{badgeDetails.icon}</span>
      <span className="badge-text">{badgeDetails.text}</span>
    </span>
  );
};

export default Badge;