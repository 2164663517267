import React, { useState, useRef, useEffect } from 'react';
import './CitySelector.css'; // Use updated styles to match Resy look
import { CITIES } from '../../assets/runClubDataIndex';

import { IoMdArrowDropdown } from "react-icons/io"; // Importing an arrow icon from react-icons

const CitySelector = ({ selectedCity, setSelectedCity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setIsOpen(false);
  };

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="city-selector-dropdown-container" ref={dropdownRef}>
      {/* Text to toggle the dropdown */}
      <div
        className="city-selector-text"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="city-name">
          {selectedCity ? selectedCity : "Select a City"}
        </span>
        <IoMdArrowDropdown className="dropdown-icon" />
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="city-selector-dropdown">
          <div className="city-list">
            {CITIES.map((city) => (
              <div
                key={city}
                className={`city-option ${city === selectedCity ? 'selected' : ''}`}
                onClick={() => handleCitySelect(city)}
              >
                {city}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CitySelector;
